.wrapper {
  text-align: center;

  padding: 5rem 1rem 0;

  h1 {
    font-size: 1.4375rem;
    font-weight: 700;
    color: var(--color-black);

    margin-bottom: 0.75rem;
  }

  p {
    font-size: 0.9375rem;
    font-weight: 400;
    color: var(--color-black-opacity-90);
    line-height: 1.2;
  }
}

.icon {
  margin: 0 auto 3.75rem;
}

.btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;

    padding: 1rem 5rem;
}