:root {
  // colors
  --color-black: black;
  --color-black-opacity-50: rgba(0, 0, 0, 0.5);
  --color-black-opacity-60: rgba(0, 0, 0, 0.6);
  --color-black-opacity-90: rgba(0, 0, 0, 0.9);
  --color-black-secondary: #383838;
  --color-black-secondary-opacity-20: rgba(56, 56, 56, 0.2);
  --color-black-secondary-opacity-10: rgba(56, 56, 56, 0.1);
  --color-white: white;
  --color-white-opacity-70: rgba(255, 255, 255, 0.7);
  --color-white-opacity-50: rgba(255, 255, 255, 0.5);
  --color-white-opacity-30: rgba(255, 255, 255, 0.3);
  --color-white-opacity-20: rgba(255, 255, 255, 0.2);
  --color-white-opacity-15: rgba(255, 255, 255, 0.15);
  --color-white-opacity-10: rgba(255, 255, 255, 0.1);
  --color-gray: #c2c2c2;
  --color-green: #73cf71;
  --color-green-opacity-6: rgba(115, 207, 113, 0.06);
  --color-orange: #ff5f2c;
  --color-orange-opacity-20: rgba(255, 95, 44, 0.2);
  --color-orange-opacity: rgba(255, 95, 44, 0.2);
  --color-orange-transparent: #ff612c33;
  --color-light-red: #ff8080;
  --color-red-opacity-6: rgba(255, 95, 44, 0.06);
  --color-violet: #8438ff;
  --color-violet-opacity-10: rgba(132, 56, 255, 0.1);
  --color-violet-opacity-5: rgba(132, 56, 255, 0.05);
  --color-not-active: #eaeaea;
  --color-border: #ececec;
  --color-border-secondary: rgba(56, 56, 56, 0.1);

  // gradients
  --gradient-green-blue: linear-gradient(139.93deg, rgba(151, 255, 134, 1) 0%, rgba(86, 177, 244, 1) 100%);
  --gradient-yellow-orange: linear-gradient(139.93deg, rgba(255, 221, 134, 1) 0%, rgba(244, 133, 86, 1) 100%);
  --gradient-blue-violet: linear-gradient(171.45deg, #3bb4ff 0%, #637fe9 100%);
  --gradient-premium: linear-gradient(152.73deg, #09a0fd 0%, #6d8cff 21%, #b867ff 58%, #ea5db9 100%);
  --gradient-blue: linear-gradient(139.93deg, #86f8ff 0%, #565cf4 100%);
  --gradient-violet: linear-gradient(139.93deg, #ff86f2 0%, #a056f4 100%);
}
