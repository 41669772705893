.white-background {
  background: var(--color-white);
}

.main {
  margin-top: 1.75rem;

  &_title {
    background: var(--color-violet-opacity-10);
  }
}
