.footer {
  position: fixed;
  bottom: 0;
  
  width: 100%;

  display: flex;
  justify-content: space-around;

  background-color: var(--color-white);

  padding-top: 0.75rem;

  border-top: 1px solid #f8f8f9;
}

.link {
  svg {
    width: 1.5rem;
    height: 1.5rem;

    stroke: var(--color-gray);
  }

  &.active {
    svg {
      stroke: var(--color-violet);
    }
  }
}
