.bell {
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--color-white-opacity-15);
  border-radius: 50%;

  position: relative;

  svg {
    width: 20px;
    height: 22px;
  }

  &_messages {
    width: 8px;
    height: 8px;
    background: var(--color-orange);

    border-radius: 50%;

    position: absolute;
    top: 8px;
    right: 8px;

    z-index: 2;
  }
}
