.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.625rem;

  height: 2.5rem;

  background: var(--color-white-opacity-15);
  border-radius: 2.5rem;

  padding: 0 1.5625rem;

  .small {
    width: 1.1rem;
    height: 1.25rem;
  }

  .big {
    width: 1.5rem;
    height: 1.625rem;
  }
}
