.range {
  padding-right: 1rem;
  &_title {
    font-size: 1.125rem;
    color: var(--color-black);
    font-weight: 600;

    margin-bottom: 0.75rem;
  }

  &_inputs {
    display: flex;
    align-items: center;

    span {
      color: var(--color-black);

      margin: 0 0.25rem;
    }

    input {
      width: 5.75rem;

      border: 1px solid var(--color-black-secondary-opacity-20);
      border-radius: 0.5rem;

      padding: 0.5rem;
      padding-right: 0.625rem;

      font-size: 0.9375rem;

      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    button {
      background: var(--color-violet-opacity-10);
      border-radius: 0.5rem;

      text-transform: uppercase;

      font-size: 0.9375rem;

      padding: 0.5625rem 1rem;
      margin-left: 1rem;
    }
  }

  &_track {
    width: 95%;
    height: 0.25rem;

    background: var(--color-violet-opacity-10);
    border-radius: 0.125rem;

    margin-top: 2rem;
    margin-bottom: 2.75rem;
  }

  &_thumb {
    width: 1.75rem;
    height: 1.75rem;

    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
}
