.item {
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-border);

  &_date {
    color: var(--color-gray);
    font-size: 0.75rem;

    text-align: center;
    margin-bottom: 20px;
  }
}

.transactions {
  padding: 0 1.5rem;

  display: grid;
  row-gap: 1.25rem;

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-type {
      display: flex;
      align-items: center;
      column-gap: 0.75rem;

      p {
        color: var(--color-black);
      }
    }

    &-amount {
      display: flex;
      align-items: center;
      column-gap: 0.3125rem;

      p {
        color: var(--color-black);
        font-weight: 600;
      }

      &.replenishment,
      &.win {
        p {
          color: var(--color-green);
        }

        svg {
          color: var(--color-green);
        }
      }

      &.loss {
        p {
          color: var(--color-light-red);
        }

        svg {
          color: var(--color-light-red);
        }
      }

      svg {
        width: 0.9375rem;
        height: 0.875rem;

        color: #000;
      }
    }
  }
}
