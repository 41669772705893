.history-of-transactions {
  background: var(--color-white);
}

.history_list {
  display: grid;
  row-gap: 0.1875rem;

  margin-top: 1.75rem;
  padding-bottom: 1rem;
}