.menuIcon {
  width: 2rem;
  height: 2rem;

  display: grid;
  place-items: center;

  background: var(--color-violet-opacity-10);
  border-radius: 50%;

  svg {
    width: 20px;
    height: 20px;
  }
}
