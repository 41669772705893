.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
  padding-bottom: 0;

  .actions {
    display: flex;
    column-gap: 0.75rem;
  }

  &.settings {
    justify-content: flex-start;

    position: relative;

    padding-top: 2rem;
    padding-bottom: 1.1875rem;

    p {
      margin: 0 auto;

      font-size: 1.125rem;
      font-weight: 600;
    }

    .black {
      color: var(--color-black);
    }

    .white {
      color: var(--color-white);
    }

    button {
      position: absolute;
    }
  }

  &.bottles {
    & .link {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--color-white);

      background: var(--color-white-opacity-15);
      border-radius: 6.25rem;

      display: flex;
      align-items: center;
      column-gap: 0.25rem;

      padding: 0.7188rem 1rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &.back {
    padding-top: 1.3125rem;
  }

  &_menu {
    background: transparent;

    width: 18px;
    height: 16px;

    position: relative;

    span {
      display: block;
      width: 1rem;
      height: 1.5px;

      background: var(--color-white);
      border-radius: 1px;

      position: absolute;
      left: 0;
      top: 7.5px;
    }

    &::before,
    &::after {
      content: '';
      display: block;

      height: 1.5px;

      background: var(--color-white);
      border-radius: 1px;

      position: absolute;
      left: 0;
      transition: all 0.3s;
    }

    &::before {
      top: 0;
      width: 0.625rem;
    }

    &::after {
      bottom: 0;
      width: 1rem;
    }
  }
}
