.orange-background {
  background: var(--gradient-yellow-orange);
}

.green-background {
  background: var(--gradient-green-blue);
}

.bottle {
  padding: 1.5rem 1rem 0;

  &_name {
    margin-bottom: 1.5rem;

    font-size: 0.9375rem;
    font-weight: 600;
  }

  &_img {
    margin: 0 auto;
    margin-bottom: 1.75rem;

    display: block;

    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.09))
      drop-shadow(0px 32px 19px rgba(0, 0, 0, 0.05)) drop-shadow(0px 56px 23px rgba(0, 0, 0, 0.01))
      drop-shadow(0px 88px 25px rgba(0, 0, 0, 0));
  }

  &_subtitle {
    font-size: 0.75rem;
    color: var(--color-white-opacity-70);

    text-align: center;

    margin-bottom: 0.375rem;
  }

  &_title {
    font-weight: 600;
    font-size: 1.375rem;

    text-align: center;
  }

  &_timer {
    background: transparent;

    font-size: 1.75rem;
  }
}

.participants {
  background: var(--color-white);
  border-radius: 2.5rem 2.5rem 0 0;

  width: 100%;
  height: 22.5rem;

  position: absolute;
  bottom: 0;
}

.info {
  height: auto;
}
