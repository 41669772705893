.iconWithText {
  font-size: 0.75rem;
  color: var(--color-black);

  width: 6.625rem;

  display: grid;
  place-items: center;
  row-gap: 0.25rem;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.iconBlock {
  width: 3.5rem;
  height: 3.5rem;

  background: var(--color-violet-opacity-5);
  border-radius: 50%;

  display: grid;
  place-items: center;
}
