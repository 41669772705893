.notification {
    background: var(--color-white);
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.5rem 1.5rem 1.25rem;

    &-status {
      display: flex;
      column-gap: 0.375rem;
      align-items: center;

      p {
        color: var(--color-black);
        font-weight: 600;
      }

      svg {
        width: 1.8125rem;
        height: 1.8125rem;
      }
    }

    &-time {
      color: var(--color-black-opacity-50);
      font-size: 0.875rem;
    }
  }

  &_main {
    display: grid;
    row-gap: 0.75rem;
    padding: 0.625rem 1.5rem;

    &.winner {
      background: var(--color-green-opacity-6);
    }

    &.defeat {
      background: var(--color-red-opacity-6);
    }

    &-title {
      color: var(--color-black-opacity-50);
      font-size: 0.75rem;
    }

    &-text {
      color: var(--color-black);
      font-size: 0.8125rem;
    }
  }

  &_footer {
    padding: 1.25rem 1.5rem 1.5rem;

    display: flex;
    justify-content: space-between;

    &-title {
      color: var(--color-black);
    }

    &-result {
      display: flex;
      align-items: center;
      column-gap: 0.3125rem;

      p {
        font-weight: 600;
      }

      svg {
        width: 0.875rem;
        height: 0.875rem;
      }

      &.winner {
        color: var(--color-green);
      }

      &.defeat {
        color: var(--color-light-red);
      }
    }
  }
}
