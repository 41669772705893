.error {
  text-align: center;

  padding: 1rem;
  padding-top: 4.5rem;

  display: flex;
  flex-direction: column;

  &_icon {
    margin: 0 auto;
    margin-bottom: 2.5rem;

    width: 9.75rem;
    height: 9.75rem;

    display: grid;
    place-items: center;

    background: var(--color-white-opacity-10);
    border-radius: 50%;

    svg {
      width: 6rem;
      height: 6rem;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex: 1;
  }

  &_title {
    font-size: 1.4375rem;
    font-weight: 700;

    margin-bottom: 0.75rem;
  }

  &_description {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

.btn {
  padding: 1rem 8.36rem;
}

.tabs {
  display: grid;
  row-gap: 0.75rem;
  button {
    width: 100%;

    padding: 1rem 5rem;
  }
}
