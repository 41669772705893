.participants {
  color: var(--color-black);
  font-size: 0.8125rem;

  &_title {
    display: flex;
    justify-content: space-between;

    font-weight: 600;
    line-height: 0.9906rem;

    padding: 0.875rem 1rem;

    border-top: 1px solid var(--color-border-secondary);

    p {
      width: 127px;
    }
    p:first-child {
      width: 52px;
    }
    p:last-child {
      width: 140px;
      text-align: right;
    }
  }

  &_list {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.875rem 1rem;

      border-top: 1px solid var(--color-border-secondary);

      &.my {
        background: var(--color-violet-opacity-5);
      }

      &.arbitrary,
      &.fixed {
        background: var(--gradient-yellow-orange);
        color: var(--color-white);
      }

      &.fixed {
        background: var(--gradient-green-blue);
      }

      .number {
        width: 52px;
      }

      .name {
        width: 127px;
      }

      .amount {
        display: flex;
        justify-content: end;
        align-items: center;

        width: 140px;
      }

      svg {
        width: 0.75rem;
        height: 0.75rem;

        margin: 0 0.25rem;
      }

      .interest {
        display: grid;
        place-items: center;

        width: 37px;

        padding: 0.0938rem 0;

        background: var(--gradient-violet);
        border-radius: 2.5rem;

        color: var(--color-white);
        font-size: 0.6875rem;
      }
    }
  }
}
