.checkbox {
  display: flex;
  align-items: center;
  column-gap: 0.6769rem;

  cursor: pointer;

  p {
    font-size: 0.9375rem;
    color: var(--color-black);
  }

  &_block {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.125rem;
    height: 1.125rem;

    background-color: rgba(56, 56, 56, 0.1);
    border: 1px solid rgba(56, 56, 56, 0.5);
    border-radius: 0.375rem;

    cursor: pointer;

    transition: background-color 0.3s;

    &.active {
      background-color: var(--color-violet);
      border: none;
    }

    svg {
      width: 0.75rem;
      height: 0.625rem;
    }
  }
}
