.bottle {
  padding-top: 1.25rem;
  border-radius: 2.5rem;

  display: block;

  color: var(--color-white);

  &.default {
    background: var(--gradient-yellow-orange);
  }

  &.fixed {
    background: var(--gradient-green-blue);
  }

  &_header {
    padding: 0 1.25rem 1rem;

    border-bottom: 1px solid var(--color-white-opacity-30);

    p {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &_characteristics {
    display: flex;
    justify-content: space-between;

    padding: 1rem 1.25rem;
  }

  &_footer {
    background: var(--color-white-opacity-20);

    &-timer {
      background: transparent;
      margin: 0 auto;
    }
  }
}
