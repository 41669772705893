.wrapper {
  width: 100%;
  height: 100vh;

  display: flex;

  position: absolute;
  top: 0;

  pointer-events: none;

  z-index: 50;

  transition: all 0.5s;

  &.active {
    pointer-events: auto;
  }
}

.clickable {
  flex: 1;

  transition: all 0.5s;

  &.active {
    background: var(--color-black-opacity-60);
  }
}

.menu {
  width: 20rem;
  height: 100%;

  background: var(--color-white);

  position: absolute;
  top: 0;
  left: -100%;

  transition: all 0.5s;

  &.active {
    left: 0;
  }

  &_info {
    width: 100%;
    height: 10.625rem;

    background: var(--gradient-blue-violet);

    padding-top: 1.75rem;
    padding-left: 1rem;

    &.premium {
      background: var(--gradient-premium);
    }

    &-avatar {
      width: 4.5rem;
      height: 4.5rem;

      border-radius: 50%;
      overflow: hidden;

      outline: 3px solid var(--color-white-opacity-30);

      margin-bottom: 1rem;
    }

    &-name {
      font-size: 0.9375rem;
      font-weight: 600;

      margin-bottom: 0.25rem;
    }

    &-phone {
      color: var(--color-white-opacity-70);
      font-size: 0.8125rem;
    }
  }

  &_list {
    padding-top: 0.75rem;

    &-item {
      display: flex;
      align-items: center;
      column-gap: 0.75rem;

      padding: 0.75rem 0;
      padding-left: 1rem;

      border-bottom: 1px solid #f8f8f8;

      &:active {
        background: var(--color-violet-opacity-5);
      }

      p {
        font-size: 0.875rem;
        color: var(--color-black-secondary);
      }
    }
  }
}
