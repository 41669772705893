.tabs {
  display: flex;
  column-gap: 0.625rem;
  justify-content: center;
}

.btn {
  background: rgba(56, 56, 56, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  font-size: 0.9375rem;

  width: 166px;
  height: 38px;

  transition: all 0.5s;

  &.active {
    color: var(--color-violet);

    outline: 1px solid var(--color-violet);
    background: var(--color-violet-opacity-10);
  }
}
