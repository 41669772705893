.bank {
  color: var(--color-black);
  background: var(--color-white);

  padding: 1.5rem 1rem 2.125rem;

  &:first-child {
    border-top: 1px solid var(--color-border-secondary);
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1.875rem;
  }

  &_title {
    display: flex;
    column-gap: 0.375rem;
    align-items: center;

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &_amount {
    display: flex;
    column-gap: 0.3125rem;

    &.winner {
      color: var(--color-green);
    }
    &.defeat {
      color: var(--color-light-red);
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &_main {
    display: grid;
    row-gap: 0.75rem;

    &-subtitle {
      font-size: 0.75rem;
      color: var(--color-black-opacity-50);

      margin-bottom: 5px;
    }

    &-title {
      font-size: 0.8125rem;
    }
  }

  &_timer {
    background: var(--color-violet-opacity-10);

    padding: 0.25rem 1rem;
    color: var(--color-violet);

    height: auto;

    p {
      order: 1;
    }

    svg {
      order: 2;
    }
  }
}
