.transaction {
    width: 2.25rem;
    height: 2.25rem;

    background: var(--color-violet-opacity-10);

    display: grid;
    place-items: center;

    border-radius: 0.625rem;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}