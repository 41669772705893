.characteristics {
  width: 5.8125rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;

  font-size: 0.75rem;
  font-weight: 600;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.icon {
  width: 3.5rem;
  height: 3.5rem;

  display: grid;
  place-items: center;

  background: var(--color-white-opacity-20);
  border-radius: 50%;
}
