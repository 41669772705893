.bottles {
  &_wrapper {
    padding: 1.5rem 1rem 3rem;
    background: var(--color-white);

    margin-top: 1.25rem;
    min-height: 91%;

    border-radius: 2.5rem 2.5rem 0 0;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;

    margin-bottom: 1.5rem;

    h2 {
      color: var(--color-black);
      font-size: 1.375rem;
      font-weight: 600;
    }

    p {
      color: var(--color-violet);
    }

    button {
      background: transparent;

      display: flex;
      align-items: center;
      column-gap: 0.3438rem;

      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }

  &_items {
    display: grid;
    row-gap: 1rem;
  }
}
