.wrapper {
  text-align: center;

  padding-top: 6.6875rem;

  &.nothing {
    padding-top: 2rem;
  }
}

.icon {
  margin: 0 auto;

  margin-bottom: 3.75rem;
}

.title {
  font-size: 1.4375rem;
  font-weight: 700;
  color: var(--color-black);

  margin-bottom: 0.75rem;
}

.subtitle {
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--color-black-opacity-90);
}

.button {
  padding: 1rem 80px;
  width: 21.4375rem;

  margin: 0 auto;
  margin-top: 3.75rem;
}
