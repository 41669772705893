.wrapper {
    text-align: center;

    margin-top: 4.5rem;
}

.title {
  font-size: 1.4375rem;
  font-weight: 700;
  color: var(--color-black);

  margin-bottom: 0.75rem;
}

.subtitle {
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--color-black-opacity-90);
}
