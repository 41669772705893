.white-background {
  background: var(--color-white);
}

.wrapper {
  margin-top: 1.5rem;
  border-top: 1px solid var(--color-border-secondary);

  &.notifications {
    background: var(--color-border-secondary);
  }
}

.notification_list {
  display: grid;
  row-gap: 10px;
}
