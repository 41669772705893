html {
  height: -webkit-fill-available;
}

.main-header {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.82px;
  text-align: left;
}

.second-header {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #afafaf;
}

.main_bank_container .second-header {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}

.main_bank_container img {
  margin: 24px;
}

.space {
  height: 24px;
}
