.icon {
  width: 9.75rem;
  height: 9.75rem;

  display: grid;
  place-items: center;

  border-radius: 50%;

  svg {
    width: 6rem;
    height: 6rem;
  }

  &.violet {
    background: var(--color-violet-opacity-10);
  }

  &.white {
    background: var(--color-white-opacity-10);
  }
}
