.info {
  background: var(--color-white);
  border-radius: 2.5rem 2.5rem 0 0;

  width: 100%;
  height: 24.25rem;

  padding: 1.5rem;
  padding-bottom: 0;
}

.subtitle {
  color: var(--color-black-opacity-50);
  font-size: 0.8125rem;
  line-height: 0.9906rem;

  margin-bottom: 0.25rem;
}

.title {
  font-weight: 600;
  font-size: 0.9375rem;
  color: var(--color-black);
  line-height: 18.29px;
}

.line {
  width: 100%;
  height: 1px;
  background: var(--color-black-secondary-opacity-10);

  margin: 1rem 0;
}

.date {
  display: flex;
  justify-content: space-between;
}
