.wrapper {
  padding: 1.4375rem 1rem;

  &_intro {
    width: 100%;
    background: var(--gradient-blue);
    border-radius: 2.5rem;

    padding-top: 0.5625rem;

    .img_bank {
      width: 19.625rem;
      height: 19.625rem;

      display: block;

      margin: 0 auto;
      margin-bottom: 0.5rem;
    }
  }

  &_info {
    padding: 0 1.25rem 0.1875rem;
  }

  &_item {
    &-subtitle {
      font-size: 1.125rem;
      color: var(--color-white-opacity-70);
      margin-bottom: 0.125rem;
    }

    &-title {
      display: flex;
      align-items: center;
      column-gap: 0.1875rem;

      margin-bottom: 7px;

      svg {
        width: 1rem;
        height: 1rem;
      }

      p {
        font-size: 2.25rem;
      }

      img {
        width: 1rem;
        height: 1rem;
      }
    }

    .timer {
      background: transparent;
      padding: 0;

      height: auto;

      column-gap: 5px;

      p {
        font-size: 0.9375rem;
      }
    }
  }

  &_action {
    text-align: center;

    margin-top: 3.75rem;

    p {
      margin-bottom: 0.375rem;
    }

    img {
      width: 1.9375rem;
      height: 1.9375rem;
    }

    button {
      width: 100%;

      padding: 0.6563rem 5rem;
    }
  }
}
