.info {
  width: 21.4375rem;
  height: 2.5rem;

  display: grid;
  place-items: center;

  font-size: 0.9375rem;
  font-weight: 600;

  background: var(--color-orange);
  border: 1px solid var(--color-white-opacity-50);
  border-radius: 2.5rem
}
