.main_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: aliceblue;
  // background: linear-gradient(139.93deg, #ff86f2 -23.36%, #a056f4 72.8%);
}

.banksListContainer {
  // background-color: aquamarine;
}
.content {
  // background-color: white;
  border-radius: 40px 40px 0px 0px;
  display: flex;
  height: inherit;
  flex-direction: column;
  align-items: center;
}
