.participants {
  &_header {
    display: flex;
    align-items: start;
    justify-content: space-between;

    padding-left: 1.5rem;
    margin-bottom: 0.75rem;

    &-title {
      color: var(--color-black);
      font-size: 0.9375rem;
      font-weight: 600;

      margin-top: 1.25rem;
    }
  }

  &_footer {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;

    padding: 0 1rem;

    button {
      width: 100%;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
