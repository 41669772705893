.filters {
  overflow-x: hidden;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  pointer-events: none;

  z-index: 50;

  transition: all 0.5s;

  &.active {
    pointer-events: auto;
  }

  &_btn {
    width: 18.75rem;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 21.25rem;
    height: 100%;

    position: absolute;
    bottom: 0;
    right: -100%;

    transition: all 0.5s;

    background: var(--color-white);
    padding: 1.25rem;
    padding-right: 0;

    &.active {
      right: 0;
    }
  }

  &_body {
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      padding-right: 1rem;

      &-filter {
        display: flex;
        column-gap: 0.375rem;
        background: transparent;
      }

      p {
        font-size: 1.375rem;
        color: var(--color-black);
        font-weight: 600;
      }
      svg {
        stroke: var(--color-black);

        width: 1.5rem;
        height: 1.5rem;
      }

      &-reset {
        background: var(--color-orange-opacity-20);
        color: var(--color-orange);
        font-size: 0.875rem;

        padding: 0.25rem 0.625rem;
        border-radius: 0.5rem;
      }
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background: var(--color-black-secondary-opacity-10);

  margin: 1.5rem 0;
}

.clickable {
  flex: 1;

  transition: all 0.5s;

  &.active {
    background: var(--color-black-opacity-60);
  }
}
