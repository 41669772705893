.starIcon {
  display: block;

  margin: 4.125rem auto 2.1875rem;
}

.main {
  padding: 0 1rem;
  text-align: center;

  &_subtitle {
    font-size: 0.75rem;
    color: var(--color-white-opacity-70);

    margin-bottom: 7px;
  }

  &_title {
    font-size: 1.375rem;
    font-weight: 600;
  }
}

.footer {
  width: 100%;
  height: 24.25rem;

  background: var(--color-white);
  border-radius: 2.5rem 2.5rem 0 0;

  position: absolute;
  bottom: 2.4688rem;
  left: 0;
  right: 0;

  &_header {
    display: flex;
    justify-content: space-between;

    padding-left: 1.5rem;

    &-title {
      font-size: 0.9375rem;
      font-weight: 600;
      color: var(--color-black);

      padding-top: 20px;
    }
  }
  button {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
  }
}
