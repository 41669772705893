.back {
  width: 2.5rem;
  height: 2.5rem;

  display: grid;
  place-items: center;

  border-radius: 50%;

  &.white {
    background: var(--color-white-opacity-15);
    
    svg {
      stroke: var(--color-white);
    }
  }

  &.black {
    background: var(--color-violet-opacity-5);

    svg {
      stroke: var(--color-black);
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
