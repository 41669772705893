.list {
  margin-top: 0.75rem;
}

.item {
  font-size: 0.8125rem;
  display: flex;
  justify-content: space-between;

  padding: 0.875rem 1rem;

  border-bottom: 1px solid var(--color-violet-opacity-5);

  &_name {
    color: var(--color-black);
  }

  &_amount {
    color: var(--color-violet);
    display: flex;
    column-gap: 0.25rem;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
