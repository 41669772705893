.checkboxes {
  &_title {
    font-size: 1.125rem;
    color: var(--color-black);
    font-weight: 600;

    margin-bottom: 1rem;
  }

  &_items {
    display: flex;
    column-gap: 2.625rem;

    flex-wrap: wrap;

    &.long {
      column-gap: 0.9375rem;
      row-gap: 1.125rem;
    }
  }
}
