.orange-background {
  background: var(--gradient-yellow-orange);
}

.main {
  padding: 0 1rem;

  h1 {
    font-size: 1.375rem;
    font-weight: 700;

    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 0.875rem;
  }
}

.input {
  text-align: center;

  display: flex;
  flex-direction: column;

  margin-top: 6.0625rem;

  &_subtitle {
    color: var(--color-white-opacity-70);
    font-size: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.125rem;

    svg {
      width: 0.75rem;
      height: 0.8125rem;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.4375rem;

  margin: 0.5rem 0;
  max-width: 100%;

  svg {
    width: 1.9375rem;
    height: 1.9375rem;
  }

  input {
    max-width: 100%;

    background: transparent;

    color: var(--color-white);
    font-size: 2.6875rem;
    font-weight: 600;

    margin-top: 0;

    direction: ltr;
    text-align: right;

    &::placeholder {
      color: var(--color-white);
      font-size: 2.6875rem;
    }
  }
}

.button {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}
