.wrapper {
  padding: 0 1rem;

  text-align: center;

  h1 {
    color: var(--color-black);
    font-size: 1.4375rem;
    font-weight: 700;

    margin-top: 12.5rem;
  }

  p {
    color: var(--color-black-opacity-90);
    font-size: 0.9375rem;
    font-weight: 400;

    margin-top: 0.75rem;
  }
}

.btn {
  padding: 1rem 5.4375rem;

  position: absolute;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}
