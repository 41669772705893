.modal {
  width: 100%;
  height: 100%;

  padding: 0 1rem;

  display: grid;
  place-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  background: var(--color-black-opacity-60);

  &_wrapper {
    width: 100%;

    background: var(--color-white);
    border-radius: 2.5rem;

    padding: 1.25rem;

    color: var(--color-black);
    text-align: center;

    svg {
      display: block;

      width: 2.75rem;
      height: 2.75rem;

      margin: 0 auto;
      margin-bottom: 2.125rem;
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5237rem;

      margin-bottom: 0.75rem;
    }

    &-text {
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.4063rem;

      margin-bottom: 3.75rem;
    }

    &-tabs {
      display: grid;
      row-gap: 0.75rem;

      button {
        width: 100%;
      }
    }
  }
}
