.loading {
  width: 100%;
  height: 100%;

  background: var(--color-black-opacity-60);

  position: absolute;
  top: 0;
  left: 0;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: var(--color-white-opacity-60);
  }

  &_spinner {
    width: 2.0625rem;
    height: 2.0625rem;
    border: 0.2188rem solid var(--color-white-opacity-50);
    border-top-color: var(--color-white);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
