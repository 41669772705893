.button {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;

  font-size: 0.875rem;

  background: var(--color-white-opacity-15);
  border-radius: 100px;
  
  padding: 0.7188rem 1rem;
  cursor: pointer;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
}
