.button {
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-white);

  padding: 1rem 5.625rem;

  border-radius: 1rem;

  display: flex;
  align-items: center;
  column-gap: 0.33rem;

  justify-content: center;

  &.contained {
    &.violet {
      background-color: var(--color-violet);
    }

    &.white {
      color: var(--color-violet);
      background-color: var(--color-white);

      &.opacity {
        color: var(--color-white);
        background-color: var(--color-white-opacity-30);
      }
    }

    &.orange {
      background-color: var(--color-orange);

      &.opacity {
        background-color: var(--color-orange-opacity);
        color: var(--color-orange);
      }
    }
  }

  &.outlined {
    background: transparent;

    &.violet {
      border: 1px solid var(--color-violet);
      color: var(--color-violet);
    }

    &.white {
      border: 1px solid var(--color-white);
      color: var(--color-white);
    }
  }

  &.not-active {
    background-color: var(--color-not-active);
    color: var(--color-gray);

    &.white {
      background-color: var(--color-white-opacity-20);
      color: var(--color-white-opacity-50);
    }
  }

  &_whiteStar {
    svg {
      order: 2;
    }
  }
}

.icon {
  &_whiteStar {
    width: 0.8125rem;
    height: 0.8125rem;
  }

  &_plus {
    width: 16px;
    height: 1rem;
  }
}
