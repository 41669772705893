.small {
  &.violet {
    width: 4.8125rem;
    height: 3rem;

    background: var(--color-violet-opacity-10);
    border-radius: 0 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.375rem;

    p {
      font-size: 0.9375rem;
    }

    svg {
      width: 0.5rem;
      height: 0.6rem;
    }
  }

  &.white {
    width: 3.5rem;
    height: 3.5rem;

    background: var(--color-white-opacity-10);

    display: grid;
    place-items: center;

    border-radius: 0 2.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
