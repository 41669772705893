.wrapper {
  text-align: center;

  &_icon {
    margin: 0 auto;
    margin-top: 5.5625rem;
  }

  h1 {
    font-size: 1.4375rem;
    font-weight: 700;
    color: var(--color-black);

    margin-top: 3.75rem;
  }

  .subtitle {
    font-size: 0.9375rem;
    color: var(--color-black-opacity-90);
    font-weight: 400;

    margin-top: 0.75rem;
  }
}
