.wrapper {
  text-align: center;

  position: relative;
  top: calc(50% - 100px);
  left: 0;

  padding: 0 1rem;

  .list {
    display: grid;
    row-gap: 0.75rem;
  }

  .item {
    background: var(--color-white);
    border-radius: 1rem;

    color: var(--color-violet);
    font-weight: 600;

    text-align: center;

    position: relative;

    padding: 1rem 0;

    &_numbers {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      position: absolute;
      top: 0.5rem;
      left: 18px;
    }

    img {
      width: 1.9375rem;
      height: 1.9375rem;
    }
  }

  .back {
    color: var(--color-white);

    display: inline-block;
    margin-top: 1.6875rem;
  }
}
