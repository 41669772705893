.wrapper {
  &_header {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;

    text-align: center;

    img {
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.09))
        drop-shadow(0px 32px 19px rgba(0, 0, 0, 0.05)) drop-shadow(0px 56px 23px rgba(0, 0, 0, 0.01))
        drop-shadow(0px 88px 25px rgba(0, 0, 0, 0));
    }
  }
}
